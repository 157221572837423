import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty, filter } from "lodash";
import { Button, Input } from "../../../../../components/base";
import BudgetField from "./BudgetField.component";
import language from "../../../../../languages";
import {
  calculateAllSemester,
  commaFormatted,
} from "../../../../../utils/transformer.util";
import Kurikulum from "../../../../Kurikulum/Kurikulum.view";

export default class BudgetForm extends Component {
  constructor(props) {
    super(props);
    this.onManagerial = this.onManagerial.bind(this);
    this.onKurikulum = this.onKurikulum.bind(this);
    this.onKesiswaan = this.onKesiswaan.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickCheckbox = this._onClickCheckbox.bind(this);
    this.onABP = this.onABP.bind(this);
    //
    this.onAddSemesterOne = this.onAddSemesterOne.bind(this);
    this.onAddSemesterTwo = this.onAddSemesterTwo.bind(this);
    this.onDeleteSemesterOne = this.onDeleteSemesterOne.bind(this);
    this.onDeleteSemesterTwo = this.onDeleteSemesterTwo.bind(this);
    this.onChangeSemesterOne = this.onChangeSemesterOne.bind(this);
    this.onChangeSemesterTwo = this.onChangeSemesterTwo.bind(this);
  }
  onKurikulum() {
    const { onKurikulum } = this.props;
    onKurikulum("kurikulum");
  }
  onKesiswaan() {
    const { onKesiswaan } = this.props;
    onKesiswaan("kesiswaan");
  }

  onABP() {
    const { onABP } = this.props;
    onABP("ABP");
  }
  onManagerial() {
    const { onManagerial } = this.props;
    onManagerial("managerial");
  }

  // console.log(kurikulum)

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
    // console.log(onChange)
  }

  _onClickCheckbox(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  onAddSemesterOne() {
    const { onAddSemester } = this.props;
    onAddSemester("semesterOne");
  }

  onAddSemesterTwo() {
    const { onAddSemester } = this.props;
    onAddSemester("semesterTwo");
  }

  onDeleteSemesterOne(idx) {
    const { onDeleteSemester, selectedDivisi } = this.props;
    onDeleteSemester("semesterOne", idx, selectedDivisi);
  }

  onDeleteSemesterTwo(idx) {
    const { onDeleteSemester, selectedDivisi } = this.props;
    onDeleteSemester("semesterTwo", idx, selectedDivisi);
  }

  onChangeSemesterOne(event) {
    const { onChange, selectedDivisi } = this.props;
    onChange(event, "semesterOne", selectedDivisi);
  }

  onChangeSemesterTwo(event) {
    const { onChange, selectedDivisi } = this.props;
    onChange(event, "semesterTwo", selectedDivisi);
  }

  render() {
    const {
      semesterOne,
      semesterOneError,
      semesterTwo,
      semesterTwoError,
      coa,
      type,
      status,
      handleEditBudgetDetail,
      code,
      onUpdateForm,
      handleDeleteBudgetDetail,
      onUpdateError,
      onSearchCoa,
      onSearchTema,
      user,
      recommendations,
      workflow,
      plafonds,
      tema,
      selectedDivisi,
      handleFileUpload,
      onRemoveFile,
      handleFileDownload,
      onClickCheckbox,
    } = this.props;

    // console.log(this.props)
    // console.log(onSearchTema)
    //console.log(tema)
    const { user_group } = user;
    let canAdd = user_group.name === "Keuangan Sekolah" ? true : false;
    let countSummary = calculateAllSemester(
      semesterOne,
      semesterTwo,
      plafonds,
      selectedDivisi
    );
    // console.log(semesterOne);

    if (
      canAdd &&
      workflow.next_role &&
      workflow.next_role !== user_group.name
    ) {
      canAdd = false;
    }

    let plafondLength = plafonds ? plafonds.length : 0;
    let tableColSpan = (type === "INCOME" ? 11 : 12) + plafondLength;

    // console.log(tableColSpan)

    let semesterTwoData = filter(semesterTwo, (o, index) => {
      if (o.divisi_id === selectedDivisi) {
        o.realIndex = index;
        return true;
      }
      return false;
    });

    //console.log(semesterTwo)

    let semesterOneData = filter(semesterOne, (o, index) => {
      if (o.divisi_id === selectedDivisi) {
        o.realIndex = index.toString();
        return true;
      }
      return false;
    });
    //console.log(semesterTwoData)
    // console.log(semesterOneData)

    return (
      <div className="manage-budget__table-wrapper">
        <table className="manage-budget__form-table table">
          <thead>
            <tr>
              {type === "OUTCOME" && <th rowSpan={2}>Nama Tema</th>}
              {type === "OUTCOME" && <th rowSpan={2}>Target</th>}
              {type === "OUTCOME" && <th rowSpan={2}>Waktu Pelaksanaan</th>}

              <th rowSpan={2}>Keterangan</th>
              <th rowSpan={2}>Kuantitas</th>
              <th rowSpan={2}>Nominal</th>
              <th rowSpan={2}>Periode</th>
              <th colSpan={plafondLength}>Alokasi anggaran</th>
              <th rowSpan={2} colSpan={1}>
                Jumlah
              </th>
              <th className="fileRowSpan" rowSpan={2}>
                Upload File
              </th>
              <th rowSpan={2} colSpan={2}></th>
            </tr>
            <tr>
              {map(plafonds, (item) => (
                <th key={`plafond_${item.value}`}>{item.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={tableColSpan}>
                <div className="manage-budget__add-more">
                  <h3>Semester I Tahun Ajaran Berikutnya</h3>
                  <div>
                    <Button
                      onClick={this.onAddSemesterOne}
                      title={
                        language.translate.FINANCE__BUDGETING__ADD_LINE__TITLE
                      }
                    />
                  </div>
                </div>
              </td>
            </tr>
            {map(semesterOneData, (data, idx) => (
              <BudgetField
                key={`budget_field_${idx}`}
                code={code}
                status={status}
                type={type}
                coa={coa}
                idx={data.realIndex}
                data={data}
                isNew={isEmpty(data.unique_id)}
                error={semesterOneError[data.realIndex]}
                handleEditBudgetDetail={handleEditBudgetDetail}
                handleDeleteBudgetDetail={handleDeleteBudgetDetail}
                handleFileUpload={handleFileUpload}
                handleFileDownload={handleFileDownload}
                onUpdateForm={onUpdateForm}
                onUpdateError={onUpdateError}
                onChange={this.onChangeSemesterOne}
                onDeleteSemester={() =>
                  this.onDeleteSemesterOne(data.realIndex)
                }
                onSearchTema={onSearchTema}
                onSearchCoa={onSearchCoa}
                user={user}
                recommendations={recommendations[data.id]}
                workflow={workflow}
                plafonds={plafonds}
                tema={tema}
                onClickCheckbox={onClickCheckbox}
              />
            ))}

            {/* {console.log(semesterOneData)} */}

            <tr className="manage-budget__summary">
              <td colSpan={type === "INCOME" ? 6 : 9}>
                {type === "INCOME" ? "Total Pendapatan" : "Total Pengeluaran"}
              </td>
              {map(countSummary, (value, key) => (
                <td className="nominal">{commaFormatted(value) || 0}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
BudgetForm.propTypes = {
  semesterOne: PropTypes.array.isRequired,
  semesterTwo: PropTypes.array.isRequired,
  inventaris: PropTypes.array,
  semesterOneError: PropTypes.array.isRequired,
  semesterTwoError: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onAddSemester: PropTypes.func,
  onDeleteSemester: PropTypes.func,
  handleEditBudgetDetail: PropTypes.func,
  handleDeleteBudgetDetail: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFileDownload: PropTypes.func,
  onUpdateForm: PropTypes.func,
  onUpdateError: PropTypes.func,
  code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  coa: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onSearchCoa: PropTypes.func,
  onSearchTema: PropTypes.object,
  user: PropTypes.object,
  recommendations: PropTypes.object,
  workflow: PropTypes.array,
  plafonds: PropTypes.array,
  tema: PropTypes.array,
};
BudgetForm.defaultProps = {
  inventaris: null,
  onAddSemester: noop,
  onDeleteSemester: noop,
  handleEditBudgetDetail: noop,
  handleDeleteBudgetDetail: noop,
  handleFileUpload: noop,
  handleFileDownload: noop,
  onUpdateForm: noop,
  onUpdateError: noop,
  onChange: noop,
  onSearchTema: noop,
  onSearchCoa: noop,
  user: null,
  recommendations: {},
  workflow: null,
  tema: [],
};
