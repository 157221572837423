import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  map, noop, isEmpty,
  filter, isEqual,
} from 'lodash';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import {
  Select, Input, SearchSelect, Button,
} from '../../../../components/base';
import SelectCoa from '../../../../components/SelectCoa/SelectCoa.component';
import language from '../../../../languages';
import { errorAlert } from '../../../../utils/alert.util';


const calculateTotal = (list = null) => {
  let total = 0;
  if (!isEmpty(list)) {
    list.forEach((item) => {
      if (item.amount !== '') {
        total += parseFloat(item.amount);
      }
    });
  }
  return total;
};

export default class ManageFundRequest extends Component {
  constructor(props) {
    super(props);
    this._setForm = this._setForm.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._getData = this._getData.bind(this);
    this._getDetailHead = this._getDetailHead.bind(this);
    this._addListItem = this._addListItem.bind(this);
    this._onChangePeriode = this._onChangePeriode.bind(this);
    this._onChangeHead = this._onChangeHead.bind(this);
    this._onChangeCoaFilter = this._onChangeCoaFilter.bind(this);
    this._getCoaOptions = this._getCoaOptions.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._loadBudgetOptions = this._loadBudgetOptions.bind(this);
    this.onSubmitApproval = this.onSubmitApproval.bind(this);
    this.onReject = this.onReject.bind(this);
    this.state = {
      filters: {
        head: null,
        periode: null,
        coa: null,
      },
      form: {
        value: {
          amount: commaFormatted(0),
          code_of_account: {
            code: null,
            title: '',
            type: '',
          },
          description: '',
          file: [],
          periode: null,
          selectedBudget: null,
          details: [],
        },
        error: {},
      },
      coa: [],
      budgets: [],
      workflow: {},
    };
  }

  componentDidMount() {
    const { location, handleGetPeriodeOptions } = this.props;
    const { data = {} } = location.state;

    if (data.id) {
      this._getData(data.id);
    }

    handleGetPeriodeOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters: prevFilters } = prevState;
    const { filters } = this.state;

    if (!isEqual(prevFilters.head, filters.head)) {
      this._getCoaOptions(null, filters.head);
    }
  }

  onSubmitApproval() {
    const { handleSubmitApproval, location, history } = this.props;
    const { data } = location.state;

    handleSubmitApproval({
      id: data.id,
    }, history.goBack);
  }

  onReject() {
    const { handleReject, history, location } = this.props;
    const { data } = location.state;

    handleReject({
      id: data.id,
    }, history.goBack);
  }

  _onChangePeriode(e) {
    const { form } = this.state;
    const { handleGetBudgetOptions } = this.props;
    const { value } = e.target;
    const newForm = { ...form };

    newForm.value.periode = value;
    newForm.value.head = '';

    handleGetBudgetOptions({
      filters: {
        periode: value,
      },
    });

    this.setState(prevState => ({
      ...prevState,
      form: newForm,
    }));
  }

  _onChangeHead(e) {
    const { form } = this.state;
    const { value } = e.target;
    const { handleGetFundRequestAvailableCoa } = this.props;
    const newForm = { ...form };

    newForm.value.head = value;

    handleGetFundRequestAvailableCoa({
      head: value.id,
    });

    this.setState(prevState => ({
      ...prevState,
      form: newForm,
    }));
  }

  _onChangeCoaFilter(e) {
    const { filters } = this.state;
    const { value, dataset } = e.target;
    const {
      arrayPosition = null,
    } = dataset;


    if (value.code === '' && value.title === '') {
      this.setState((prevState) => {
        const { form } = prevState;
        const { value: formValue } = form;
        const newValue = { ...formValue };
        const { details } = formValue;

        if (!isEmpty(details)) {
          Object.keys(details[arrayPosition]).forEach((prop) => {
            newValue.details[arrayPosition][prop] = '';
          });
        }

        newValue.amount = calculateTotal(newValue.details);

        return {
          form: {
            value: {
              ...prevState.form.value,
              ...newValue,
            },
            error: {
              ...prevState.form.error,
            },
          },
        };
      });
    } else {
      filters.coa = value;

      this._getDetailHead(value, dataset.arrayPosition);

      this.setState(prevState => ({
        ...prevState,
        filters,
      }));
    }
  }

  async _getData(id) {
    const { handleGetFundRequestById } = this.props;
    const data = await handleGetFundRequestById({ id });
    this._getCoaOptions(null, data.head.id);
    this._setForm(data);
  }

  async _getCoaOptions(keyword = null, head = null) {
    const { handleGetFundRequestAvailableCoa } = this.props;
    const { form } = this.state;
    const { value } = form;
    let headValue = null;
    if (head === '' || head === null) {
      headValue = value.head.id;
    }

    const res = await handleGetFundRequestAvailableCoa({
      keyword,
      head: headValue,
    });

    return res;
  }

  async _getDetailHead(coa, pos) {
    const { form } = this.state;
    const { handleGetAvailableBudgetDetails } = this.props;

    const res = await handleGetAvailableBudgetDetails({
      code: coa.code,
      type: 'account',
      head: form.value.head.id,
    });

    form.value.details[pos] = res;

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          details: form.value.details,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  async _loadBudgetOptions() {
    const { form } = this.state;
    const { handleGetBudgetOptions } = this.props;

    const res = await handleGetBudgetOptions({
      filters: {
        periode: form.value.periode,
      },
    });

    return res;
  }

  _addListItem() {
    const { form } = this.state;
    const newForm = { ...form };
    const { value } = newForm;

    if (typeof value.details === 'undefined') {
      value.details = [];
    }
    value.details.push({});
    this.setState({
      newForm,
    });
  }

  async _setForm(data) {
    this.setState({
      form: {
        value: {
          id: data.id,
          amount: commaFormatted(calculateTotal(data.details)),
          head: data.head,
          description: data.description,
          periode: data.periode,
          details: data.details || [],
        },
        error: {},
      },
      workflow: data.workflow || {},
    });
  }

  async _onFormChange(event, fieldName) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputType = 'text', arrayPosition = null,
    } = dataset;
    const { form } = this.state;
    const newForm = { ...form };

    this.setState((prevState) => {
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (!isEmpty(arrayPosition)) {
        newForm.value.details[arrayPosition][name] = formattedValue;
        if (name === 'amount') {
          newForm.value.amount = calculateTotal(newForm.value.details);
        }
      } else {
        newForm.value[name] = formattedValue;
      }

      const error = {
        ...prevState.form.error,
        [name]: '',
      };

      if (fieldName) {
        error[fieldName] = filter(error[fieldName], (err) => {
          if (err) {
            return err.length;
          }
          return {};
        });
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            ...newForm.value,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleAddFundRequest,
      history, location,
    } = this.props;
    const error = null;

    const { isEdit = false } = location.state;
    if (isEmpty(error)) {
      if (isEdit) {
        handleAddFundRequest(form.value, history.goBack);
      } else {
        handleAddFundRequest(form.value, history.goBack);
      }
    } else {
      errorAlert({
        title: isEdit ? 'Gagal Mengubah' : 'Gagal Membuat',
        errorMessage: 'Periksa lengkapi form',
      });
    }
  }

  _renderFilters(form, isEdit) {
    const { value, error } = form;
    const { periode, head } = value;
    const {
      description,
    } = value;
    const { options } = this.props;

    let headValue = {
      title: '',
      id: '',
    };

    if (head && head !== null) {
      headValue = {
        title: (head.title) ? head.title : head.desc,
        id: head.id,
      };
    }

    const budgetAndCoaFilters = (
      <div className="manage-fund-request__row-form">
        <div className="manage-fund-request__form-content">
          <SearchSelect
            async
            disabled={!isEdit}
            name="head"
            label="Head"
            value={headValue}
            error={error.head}
            onSearch={this._loadBudgetOptions}
            onClick={this._onChangeHead}
            placeholder="Pilih Head"
            rightIcon="icon-search"
          />
        </div>
      </div>
    );

    const periodeAndDescription = (
      <div className="manage-fund-request__row-form">
        <div className="manage-fund-request__form-content">
          <Select
            disabled={!isEdit}
            name="periode"
            label="Periode"
            data={options.periode.list}
            onChange={this._onChangePeriode}
            placeholder="Pilih periode"
            value={periode || ''}
            error={error.periode || ''}
          />
        </div>
        <div className="manage-fund-request__form-content">
          <Input
            disabled={!isEdit}
            name="description"
            label="Deskripsi"
            onChange={this._onFormChange}
            value={description || ''}
          />
        </div>
      </div>
    );

    if (typeof periode !== 'undefined' && periode !== null && periode !== '') {
      return (
        <div>
          {periodeAndDescription}
          {budgetAndCoaFilters}
        </div>
      );
    }
    return (
      <div>
        {periodeAndDescription}
      </div>
    );
  }

  _renderButtons() {
    const { user } = this.props;
    const { user_groups_id, user_group } = user;
    const { workflow } = this.state;

    if (!workflow.next_role && !workflow.is_done) {
      return [
        <div key="button_save">
          <Button
            type="submit"
            title="Save"
          />
        </div>,
        <div key="button_submit">
          <Button
            type="button"
            onClick={this.onSubmitApproval}
            title={(user_group.name !== 'Bendahara') ? 'Submit' : 'Approve'}
          />
        </div>,
      ];
    }
    if ((workflow.next_role === user_group.name) && !workflow.is_done) {
      const buttons = [
        <div key="button_submit">
          <Button
            type="button"
            onClick={this.onSubmitApproval}
            title={(user_group.name !== 'Bendahara') ? 'Submit' : 'Approve'}
          />
        </div>,
      ];

      if (user_group.name === 'Keuangan') {
        buttons.push([
          <div key="button_save">
            <Button
              type="submit"
              title="Save"
            />
          </div>,
        ]);
      }

      if (user_group.name !== 'Keuangan' && user_group.name !== 'Admin'
      && user_group.name !== 'Super Admin' && !workflow.is_done) {
        buttons.push([
          <div key="button_save">
            <Button
              type="button"
              onClick={this.onReject}
              title="Reject"
            />
          </div>]);
      }

      if (user_groups_id) {
        return buttons;
      }
    } else {
      if (workflow.is_done) {
        return (
          <div>
            <i>Permohonan dana telah disubmit ke {workflow.next_role}</i>
          </div>
        );
      }

      return (
        <div>
          <i>Permohonan dana telah disubmit ke {workflow.next_role}</i>
        </div>
      );
    }


    return false;
  }

  render() {
    const { form } = this.state;
    const { details } = form.value;
    const {
      options, user,
    } = this.props;
    const { user_group } = user;
    let isEdit = false;

    if (user_group.name === 'Keuangan') {
      isEdit = true;
    }

    return (
      <div className="manage-fund-request">
        <h1>
          {
            isEdit ? 'Edit Permohonan Dana' : 'Buat Permohonan Dana'
          }
        </h1>
        <form onSubmit={this._onSubmit}>
          { this._renderFilters(form, isEdit) }
          <table cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th rowSpan={2}>No.</th>
                <th rowSpan={2}>Kode</th>
                <th rowSpan={2}>Keterangan</th>
                <th rowSpan={2}>Jumlah Anggaran</th>
                <th rowSpan={2}>Sisa Anggaran</th>
                <th rowSpan={2}>Jumlah Permohonan Dana</th>
              </tr>
            </thead>
            <tbody>
              { user_group.name === 'Keuangan'
                && (
                <tr>
                  <td colSpan={6}>
                    <div className="manage-budget__add-more">
                      <div>
                        <Button
                          onClick={this._addListItem}
                          title={language.translate.FINANCE__BUDGETING__ADD_LINE__TITLE}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                )
              }
              {
                map(details, (data, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      <SelectCoa
                        noMargin
                        inputArray
                        arrayPosition={idx}
                        edit={isEdit}
                        name="parameter_code"
                        value={data.parameter_code || {}}
                        coa={options.coa}
                        onClick={this._onChangeCoaFilter}
                        placeholder="Pilih Kode"
                        rightIcon="icon-search"
                        onSearchCoa={this._getCoaOptions}
                      />
                    </td>
                    <td>
                      {!isEmpty(data) && data.desc}
                    </td>
                    <td className="nominal">
                      {!isEmpty(data) && commaFormatted(data.total)}
                    </td>
                    <td className="nominal">
                      {!isEmpty(data) && (!isEmpty(data.remains)
                        ? commaFormatted(data.remains)
                        : commaFormatted(data.total)) }
                    </td>
                    <td className="nominal">
                      <Input
                        noMargin
                        data-input-array
                        data-array-position={idx}
                        data-input-type="number"
                        type="text"
                        edit={isEdit}
                        name="amount"
                        onChange={this._onFormChange}
                        value={data.amount ? commaFormatted(data.amount) : ''}
                      />

                    </td>
                  </tr>
                ))
            }
              <tr>
                <td colSpan="5">Total</td>
                <td className="nominal">{commaFormatted(form.value.amount)}</td>
              </tr>
            </tbody>
          </table>
          <div />
          <div className="buttons-wrapper">
            {this._renderButtons()}
          </div>
        </form>
      </div>
    );
  }
}
ManageFundRequest.propTypes = {
  handleGetAvailableBudgetDetails: PropTypes.func,
  handleGetBudgetOptions: PropTypes.func,
  handleGetPeriodeOptions: PropTypes.func,
  handleGetFundRequestAvailableCoa: PropTypes.func,
  handleAddFundRequest: PropTypes.func,
  handleGetFundRequestById: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleReject: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  options: PropTypes.object,
  user: PropTypes.object,
};
ManageFundRequest.defaultProps = {
  handleGetAvailableBudgetDetails: noop,
  handleGetBudgetOptions: noop,
  handleGetPeriodeOptions: noop,
  handleGetFundRequestAvailableCoa: noop,
  handleAddFundRequest: noop,
  handleGetFundRequestById: noop,
  handleSubmitApproval: noop,
  handleReject: noop,
  options: {},
  user: {},
};
