import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, filter,
  dropRight, find, mapValues, keyBy, map,
} from 'lodash';
import { Button, Select } from '../../../../components/base';
import ListForm from '../../../../components/ListForm/ListForm.component';
import BudgetForm from './components/BudgetForm.component';
import BudgetDivisiDetail from './components/BudgetDivisiDetail.component';
import BudgetDivisiDetailList from './components/BudgetDivisiDetailList.component';

import BudgetPlafon from './components/BudgetPlafon.component';
import BudgetRapbu from './components/BudgetRapbu.component';
import language from '../../../../languages';
import {
  commaFormatted, normalizeAmount, toCamelCase, datetimeFormat, generateYearList } from '../../../../utils/transformer.util';
import { validateManageBudget, validateAllocationPlafondAdd } from '../../../../utils/validation.util';
import { BUDGET_KELOLA_ALOKASI_PENGELUARAN } from '../../../../constants/finance/budgeting/budget.constant';
import { errorAlert } from '../../../../utils/alert.util';


export default class ManageBudget extends Component {
  constructor(props) {
    super(props);
    //
    this.state = {
      form: {
        value: {},
        error: {
          plafon: '',
          tema: '',
        },
      },
      countable: {},
      selectedDivisi: null,

    };
    this.onSetForm = this.onSetForm.bind(this);
    this.onUpdateForm = this.onUpdateForm.bind(this);
    this.onUpdateError = this.onUpdateError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onClickCheckbox = this.onClickCheckbox.bind(this);
    this.onClickDivisi = this.onClickDivisi.bind(this);
    this.onAddSemester = this.onAddSemester.bind(this);
    this.onDeleteSemester = this.onDeleteSemester.bind(this);
    this.onClearForm = this.onClearForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSaveDetail = this.onSaveDetail.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this.onSearchCoa = this.onSearchCoa.bind(this);
    this._onChangeDivisi = this._onChangeDivisi.bind(this);
    this.renderPlafondRows = this.renderPlafondRows.bind(this);
    this.calculateTotalPlafond = this.calculateTotalPlafond.bind(this);
    this.renderWorkflowHistory = this.renderWorkflowHistory.bind(this);
    // this.onClickAllocation = this.onClickAllocation.bind(this);
    //
    // this._onClickAdd = this._onClickAdd.bind(this);

    this.state = {
      status: 'VIEW',
      form: {
        value: {
          period: '2019',
          unit: '',
          semesterOne: [],
          semesterTwo: [],
        },
        error: {
          period: '',
          unit: '',
          semesterOne: [],
          semesterTwo: [],
        },
      },
      pendapatan: [],
      pengeluaran: [],
      inventaris: [],
      recommendations: {},
      tema: [],
      plafon: [],
      workflow: {},
      selectedDivisi: null,
      budget: {},
      availablePlafonds: [],
      totalPlafond: {},
    };

    // console.log(this.state);
  }

  componentDidMount() {
    this.onSearchCoa();
    this.onSearchTema();
    this.onSetForm();
  }

  onSearchTema(keyword = null) {
    const divisiValue = this.state.selectedDivisi ? this.state.selectedDivisi : 1;
    const { handleGetTemaOptions } = this.props;
    handleGetTemaOptions({
      keyword,
      divisiValue,
    });

    // console.log(handleGetTemaOptions)
  }

  renderWorkflowHistory(workflow) {
    const rows = [];

    if (workflow) {
      for (const idx in workflow) {
        const {
          prev_role, next_role, action, updated_at, remarks, is_done 
} = workflow[idx];
        const roleMap = {
          // 'Korektor Perwakilan': 'Perwakilan',
          // 'Bendahara': 'Pengurus',
          // 'Manager Keuangan': 'Manager Keuangan',
          'Keuangan': 'Kepala Sekolah',
          // 'Keuangan Pusat': 'Kepala Sekolah',
          'Keuangan': 'Keuangan',

        };

        // console.log(roleMap)

        const flowAction = (action === 'reject')
          ? 'mengembalikan ke' : ' mengajukan ke';
        const nextRole = roleMap[next_role] ? roleMap[next_role] : next_role;
        const prevRole = roleMap[prev_role] ? roleMap[prev_role] : prev_role;
        let flowValue = `${prevRole} ${flowAction} ${nextRole}`;

        // console.log(action)

        if (is_done) {
          flowValue = 'RAPBS telah disetujui oleh Bagian Keuangan';
        }
        rows.push([
          <tr key={`workflow_history_item_${idx}`}>
            <td>{parseInt(idx) + 1}</td>
            <td>{flowValue}</td>
            <td>{remarks}</td>
            <td>{datetimeFormat(updated_at)}</td>
          </tr>,
        ]);
      }

      return (
        <table className="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Flow</th>
              <th>Catatan</th>
              <th>Tanggal</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      );
    }

    return false;
  }

  onSearchCoa(keyword = null) {
    const { handleGetCoaOptions, location } = this.props;
    const { type } = location.state;
    if (type === 'OUTCOME') {
      handleGetCoaOptions({
        keyword,
        classes: [500000],
        // categories: [51000, 52000, 53000, 54000, 55000, 56000, 13000],
      });
    } else {
      handleGetCoaOptions({
        keyword,
        // classes: [40000],
        classes: [400000],
      });
    }
  }

  async onSetForm(keyword = null) {
    const {
      handleGetBudgetDetail, location, handleGetDivisiOptions, handleGetPlafondOptions, handleGetTemaOptions 
} = this.props;
    const {
      account_unique_id, head_unique_id, type, value, 
    } = location.state;
    const plafond = await handleGetPlafondOptions();
    const divisi = await handleGetDivisiOptions({
      keyword,
      label: (value === 5) ? 'keuangan' : 'manajerial',
    });
    const divisiValue = parseInt(divisi[0].value);
    const tema = await handleGetTemaOptions({ keyword, divisiValue });
    const res = await handleGetBudgetDetail({ account_unique_id, head_unique_id, type });
    const availablePlafonds = filter(plafond, (data) => {
      const exists = find(res.budget.plafon, plafon => plafon.plafond_id === data.value);
      return typeof exists !== 'undefined';
    });
    // console.log(this.props)
    // console.log(tema)

    // console.log(availablePlafonds) dapet isinya rutin
    const totalPlafond = mapValues(keyBy(res.budget.plafon, 'plafond_id'), 'total');
    // console.log(res.budget.plafon) masih dapet
    // console.log(divisiValue)
    const countable = {};
    // console.log(countable)
    map(res.budget.plafon, 'plafond_id').forEach((plafondId) => {
      countable[plafondId] = {
        semesterOne: {},
        semesterTwo: {},
      };
      // console.log(countable)
      // console.log(countable) isinya alokasi anggaran / jumlah
      res.ganjil.forEach((item, index) => {
        if (item.allocations[plafondId]) {
          countable[plafondId].semesterOne[index] = item.allocations[plafondId];
        }
        // console.log(res.ganjil)
      });

      res.genap.forEach((item, index) => {
        if (item.allocations[plafondId]) {
          countable[plafondId].semesterTwo[index] = item.allocations[plafondId];
        }
      });
    });

    // console.log(res.genap)
    // console.log("res.budget.plafon")


    this.setState({
      status: (!isEmpty(res.ganjil) || !isEmpty(res.genap)) ? 'VIEW' : 'ADD',
      form: {
        value: {
          semesterOne: res.ganjil || [],
          semesterTwo: res.genap || [],
        },
        error: {
          period: '',
          semesterOne: [],
          semesterTwo: [],
        },
      },
      pendapatan: res.pendapatan || [],
      pengeluaran: res.pengeluaran || [],
      inventaris: res.inventaris || [],
      recommendations: res.recommendations || {},
      workflow: res.workflow || [],
      budget: res.budget || {},
      availablePlafonds: availablePlafonds || [],
      totalPlafond: totalPlafond || {},
      selectedDivisi: parseInt(divisi[0].value),
      countable: countable || {},
    });
    this.calculateTotalPlafond();

    // console.log(this.state.form.value.semesterOne)
  }

  onUpdateForm(data, idx) {
    const formName = Number(data.semester) === 1 ? 'semesterOne' : 'semesterTwo';
    this.setState((prevState) => {
      const newValue = prevState.form.value;
      const newError = prevState.form.error;
      newValue[formName][idx] = data;
      newError[formName][idx] = {};
      return {
        ...prevState,
        form: {
          value: {
            ...newValue,
          },
          error: {
            ...prevState.form.error,
            ...newError,
          },
        },
      };
    });
  }

  onUpdateError(data, error, idx) {
    const formName = Number(data.semester) === 1 ? 'semesterOne' : 'semesterTwo';
    this.setState((prevState) => {
      const newValue = prevState.form.error;
      newValue[formName][idx] = error;
      return {
        form: {
          value: {
            ...prevState.form.value,
          },
          error: {
            ...newValue,
          },
        },
      };
    });
  }


  _onChangeDivisi(event) {
    const { target } = event;
    const { value } = target;

    this.setState(prevState => ({
      ...prevState,
      selectedDivisi: parseInt(value),
    }), () => {
      const divisiValue = this.state.selectedDivisi;
      const keyword = null;
      const { handleGetTemaOptions } = this.props;
      handleGetTemaOptions({
        keyword,
        divisiValue,
      });
    },);

    // console.log(this.props)
  }

  onFormChange(event, formName) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputArray = false, arrayPosition = 0, countable = false, inputType, plafondId 
} = dataset;
    const { selectedDivisi } = this.state;

    this.setState((prevState) => {
      const newList = [];
      const newListError = [];
      let formattedValue = value;
      let newSemester = [];
      let newSemesterError = [];
      const countableData = { ...prevState.countable };

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        newSemester = prevState.form.value[formName];
        newSemesterError = prevState.form.error[formName];
        newSemester[arrayPosition][name] = formattedValue;
        newSemester[arrayPosition].divisi_id = selectedDivisi;
        if (!isEmpty(newSemesterError[arrayPosition])) {
          newSemesterError[arrayPosition][name] = '';
        }
        if (plafondId) {
          if (!newSemester[arrayPosition].allocations) {
            newSemester[arrayPosition].allocations = {};
          }
          newSemester[arrayPosition].allocations[plafondId] = formattedValue;
        }
      }


      // console.log("sini" + arrayPosition)
      // console.log("sini" + inputArray)
      const error = {
        ...prevState.form.error,
        ...(inputArray
          ? { [formName]: newSemesterError }
          : { [name]: '' }),
      };
      error.semesterOne = filter(error.semesterOne, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });
      error.semesterTwo = filter(error.semesterTwo, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });
      // console.log(countable);

      if (countable) {
        if (!countableData[plafondId]) {
          countableData[plafondId] = {
            semesterOne: {},
            semesterTwo: {},
          };
        }
        countableData[plafondId][formName][arrayPosition] = formattedValue;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [formName]: newSemester }
              : { [name]: formattedValue }),
          },
          error,
        },
        countable: countableData,
      };
    }, () => {
      if (countable) {
        this.calculateTotalPlafond();
      }
    });
  }

  onClickCheckbox(event, formName) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputArray = false, arrayPosition = 0, countable = false, inputType, plafondId 
} = dataset;
    const { selectedDivisi } = this.state;

    this.setState((prevState) => {
      let formattedValue = value;
      let newSemester = [];
      let newSemesterError = [];
      const countableData = { ...prevState.countable };

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        newSemester = prevState.form.value[formName];
        newSemesterError = prevState.form.error[formName];
        newSemester[arrayPosition][name] = formattedValue;
        newSemester[arrayPosition].divisi_id = selectedDivisi;
        if (!isEmpty(newSemesterError[arrayPosition])) {
          newSemesterError[arrayPosition][name] = '';
        }
        if (plafondId) {
          if (!newSemester[arrayPosition].allocations) {
            newSemester[arrayPosition].allocations = {};
          }
          newSemester[arrayPosition].allocations[plafondId] = formattedValue;
        }
      }
      const error = {
        ...prevState.form.error,
        ...(inputArray
          ? { [formName]: newSemesterError }
          : { [name]: '' }),
      };
      error.semesterOne = filter(error.semesterOne, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });
      error.semesterTwo = filter(error.semesterTwo, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });
      // console.log(countable);

      if (countable) {
        if (!countableData[plafondId]) {
          countableData[plafondId] = {
            semesterOne: {},
            semesterTwo: {},
          };
        }
        countableData[plafondId][formName][arrayPosition] = formattedValue;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [formName]: newSemester }
              : { [name]: formattedValue }),
          },
          error,

        },
        countable: countableData,

      };
    }, () => {
      if (countable) {
        this.calculateTotalPlafond();
      }
    });
  }

  onAddSemester(fieldName) {
    const { selectedDivisi } = this.state;
    this.setState(prevState => ({
      ...prevState,
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {
              divisi_id: parseInt(selectedDivisi),
              allocations: {},
            },
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  onDeleteSemester(fieldName, idx) {
    this.setState((prevState) => {
      const list = prevState.form.value[fieldName];
      const listError = prevState.form.error[fieldName];
      const countableData = { ...prevState.countable };
      const { availablePlafonds } = prevState;

      availablePlafonds.forEach((item) => {
        const field = toCamelCase(item.label);
        const plafondId = item.value;
        if (countableData[plafondId]) {
          if (countableData[plafondId][fieldName]) {
            delete countableData[plafondId][fieldName][idx];
          }
        }
      });

      list.splice(idx, 1);
      return {
        ...prevState,
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [...list],
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [...listError],
          },
        },
        countable: countableData,
      };
    }, () => {
      this.calculateTotalPlafond();
    });
  }

  onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  onClickDivisi(event) {
    const { history } = this.props;
    const { target } = event;
    const { dataset } = target;

    history.push(`/dashboard/keuangan/penganggaran/kelola-anggaran/${dataset.type}`, {
      ...this.state.form.value,
      type: dataset.type,
    });
  }

  async onSubmit(e) {
    e.preventDefault();

    const {
      form, status, budget, availablePlafonds 
} = this.state;
    const { handleAddBudgetDetail, location, history } = this.props;
    const error = validateManageBudget(form.value, budget.plafon);
    //
    // error = {semsterOne: {allocation: 'Harus di isi', }, semesterTwo: {}}
    const semesterOneErr = error.semesterOne;
    const semesterTwoErr = error.semesterTwo;
    const allocationErrors = validateAllocationPlafondAdd(form.value);


    if (error) {
      Object.keys(error).forEach((semester) => {
        error[semester].forEach((errs, index) => {
          error[semester][index] = Object.assign({}, error[semester][index], allocationErrors[semester][index]);
        });
      });
      this.setState(prevState => ({
        ...prevState,
        form: {
          value: prevState.form.value,
          error,
        },
      }));
    }

    const budgetPlafond = JSON.parse(JSON.stringify([...budget.plafon]));
    // let nominal = JSON.parse(budgetPlafond).map(d => d['nominal']);
    const nominal = {};
    for (const id in budgetPlafond) {
      nominal[budgetPlafond[id].plafond_id] = budgetPlafond[id].nominal;
    }

    const totalPlafond = {};
    const countableOne = form.value.semesterOne;
    const countableTwo = form.value.semesterTwo;

    for (const id in availablePlafonds) {
      let total = 0;
      for (const index in countableOne) {
        for (const idx in countableOne[index].allocations) {
          if (availablePlafonds[id].value == idx) {
            total += parseInt(countableOne[index].allocations[idx]);
          }
        }
      }
      for (const index in countableTwo) {
        for (const idx in countableTwo[index].allocations) {
          if (availablePlafonds[id].value == idx) {
            total += parseInt(countableTwo[index].allocations[idx]);
          }
        }
      }
      totalPlafond[availablePlafonds[id].value] = total;
    }

    this.setState({ totalPlafondTemp: totalPlafond },
      async () => {
        if (this.props.location.state.type === 'OUTCOME') {
          for (const id in this.state.totalPlafondTemp) {
            let text = '';
            for (const idx in availablePlafonds) {
              if (availablePlafonds[idx].value == id) {
                text = availablePlafonds[idx].label;
              }
            }
            // console.log(this.state.totalPlafond[id] +" === "+ nominal[id])

            if (this.state.totalPlafondTemp[id] > nominal[id]) {
              errorAlert({
                title: `Total Budget ${text } Sudah Melebihi Plafond !!`,
              });
              // history.go(0)
              return true;
            }
          }
        }

        if (filter(error.semesterOne, item => Object.keys(item).length > 0).length > 0) {
          errorAlert({
            title: 'Data ada yang salah',
          });
        } else if (filter(error.semesterTwo, item => Object.keys(item).length > 0).length > 0) {
          errorAlert({
            title: 'Data ada yang salah',
          });
        } else if (totalPlafond['1'] >= nominal) {
          errorAlert({
            title: 'Total Budget Sudah Melebihi Plafond !!',
          });
        } else {
          try {
            const res = await handleAddBudgetDetail({
              ...form.value,
              ...location.state,
            }, status);
            history.go(0);

            this.setState((prevState) => {
              const newSemesterOne = filter(res, data => data.semester === 1);
              const newSemesterTwo = filter(res, data => data.semester === 2);
              const oldSemesterOne = dropRight(prevState.form.value.semesterOne, newSemesterOne.length);
              const oldSemesterTwo = dropRight(prevState.form.value.semesterTwo, newSemesterTwo.length);
              // console.log(data.semester)

              return ({
                ...prevState,
                status: 'VIEW',
                form: {
                  value: {
                    ...prevState.form.value,
                    semesterOne: [...oldSemesterOne, ...newSemesterOne],
                    semesterTwo: [...oldSemesterTwo, ...newSemesterTwo],
                  },
                  error: {
                    ...prevState.form.error,
                  },
                  // countable: countableData,
                },
              });
            }, () => {
              const { totalPlafondTemp = {} } = this.state;
              // for(let key in res){
              //   totalPlafond[res[key].divisi_id] += res[key].total;
              // }

              this.setState(prevState => ({
                ...prevState,
                totalPlafond: totalPlafondTemp,
              }));
            });
          } catch (err) {
            // err
          }
        }
      });
  }

  async onSaveDetail({ code, ...data }) {
    const {
      form, status, budget, availablePlafonds, 
    } = this.state;
    const {
      handleAddBudgetDetail, location, history, handleEditBudgetDetail, 
    } = this.props;
    const error = validateManageBudget(form.value, budget.plafon, totalPlafond);

    const semesterOneErr = error.semesterOne;
    const semesterTwoErr = error.semesterTwo;
    const allocationErrors = validateAllocationPlafondAdd(form.value);
    const formHasError = false;

    if (error) {
      Object.keys(error).forEach((semester) => {
        error[semester].forEach((errs, index) => {
          error[semester][index] = Object.assign({}, error[semester][index], allocationErrors[semester][index]);
        });
      });
    }

    const budgetPlafond = JSON.parse(JSON.stringify([...budget.plafon]));
    // let nominal = JSON.parse(budgetPlafond).map(d => d['nominal']);
    const nominal = {};
    for (const id in budgetPlafond) {
      nominal[budgetPlafond[id].plafond_id] = budgetPlafond[id].nominal;
    }

    let totalPlafond = {};
    const countableOne = form.value.semesterOne;
    const countableTwo = form.value.semesterTwo;

    for (const id in availablePlafonds) {
      let total = 0;
      for (const index in countableOne) {
        for (const idx in countableOne[index].allocations) {
          if (availablePlafonds[id].value == idx) {
            total += parseInt(countableOne[index].allocations[idx]);
          }
        }
      }
      for (const index in countableTwo) {
        for (const idx in countableTwo[index].allocations) {
          if (availablePlafonds[id].value == idx) {
            total += parseInt(countableTwo[index].allocations[idx]);
          }
        }
      }
      totalPlafond[availablePlafonds[id].value] = total;
    }

    if (this.props.location.state.type === 'OUTCOME') {
      for (const id in totalPlafond) {
        let text = '';
        for (const idx in availablePlafonds) {
          if (availablePlafonds[idx].value == id) {
            text = availablePlafonds[idx].label;
          }
        }
        // console.log(this.state.totalPlafond[id] +" === "+ nominal[id])

        if (totalPlafond[id] > nominal[id]) {
          errorAlert({
            title: `Total Budget ${text } Sudah Melebihi Plafond !!`,
          });
          formHasError = true;
          // history.go(0)
          return false;
        }
      }
    }

    if (filter(error.semesterOne, item => Object.keys(item).length > 0).length > 0) {
      errorAlert({
        title: 'Data ada yang salah',
      });
      formHasError = true;
    } else if (filter(error.semesterTwo, item => Object.keys(item).length > 0).length > 0) {
      errorAlert({
        title: 'Data ada yang salah',
      });
      formHasError = true;
    } else if (totalPlafond['1'] >= nominal) {
      errorAlert({
        title: 'Total Budget Sudah Melebihi Plafond !!',
      });
      formHasError = true;
    } else {
      try {
        const res = await handleEditBudgetDetail({
          ...data, code,
        }, status);

        history.go(0);
      } catch (err) {
        formHasError = true;
        return false;
      }
    }
    return formHasError;
  }

  calculateTotalPlafond() {
    const { countable, totalPlafond = {} } = this.state;

    if (countable) {
      for (const key in countable) {
        const item = countable[key];
        const { semesterOne, semesterTwo } = item;
        let total = 0;
        if (semesterOne) {
          for (const index in semesterOne) {
            total += semesterOne[index];
          }
          // console.log(total)
        }
        if (semesterTwo) {
          for (const index in semesterTwo) {
            total += semesterTwo[index];
          }
        }
        totalPlafond[key] = total;
      }
    }

    this.setState(prevState => ({
      ...prevState,
      totalPlafond,
    }));
  }

  renderButton() {
    return (
      <div>
        <Button
          type="submit"
          title="Save"
        />
      </div>

    );
  }

  renderPlafondRows() {
    const {
      budget, availablePlafonds = [], totalPlafond = {}, form, tema = {}, 
    } = this.state;
    const { error } = form;
    const rows = [];

    if (availablePlafonds.length > 0) {
      availablePlafonds.forEach((item) => {
        const budgetPlafond = find(budget.plafon, o => o.plafond_id === item.value);
        let nominal = 0;
        let total = 0;
        let percent = 0;

        if (budgetPlafond) {
          nominal = budgetPlafond.nominal;
          total = totalPlafond[item.value] || 0;

          if (nominal > 0 && total > 0) {
            percent = (total / nominal) * 100;
          }
          rows.push([
            <tr key={`plafond_${item.value}`}>
              <td>
                {item.label}
              </td>
              <td className="nominal">
                {commaFormatted(total)}
                { error.plafond && error.plafond[item.value]
                  && <p className="input__err">{error.plafond[item.value]}</p>
                }
              </td>
              <td className={`nominal ${total > nominal ? 'red' : ''}`}>
                {commaFormatted(nominal)}
              </td>
              <td className={`nominal ${percent > 100 ? 'red' : ''}`}>
                {commaFormatted(percent)} %
              </td>
            </tr>,
          ]);
        }
      });
    }
    return rows;
  }

  render() {
    const {
      form,status, recommendations, workflow,
      budget, selectedDivisi, availablePlafonds,
    } = this.state;
    const { error } = form;
    const {
      semesterOne, semesterTwo,
    } = form.value;

    const {
      coa, location, tema = {},
      handleDeleteBudgetDetail, user, divisi = {},
      handleFileUpload, handleFileDownload,
    } = this.props;
    const { user_group, workingUnit = {}, school_unit = {} } = user;
    const { type, code } = location.state;
    const activeUnit = Object.values(workingUnit).length > 0 ? workingUnit : school_unit;
    const { id: activeUnitId } = activeUnit;

    let divisiValue = selectedDivisi || '';
    if (divisiValue === null && Object.keys(divisi).length > 0) {
      divisiValue = divisi.list[0].value;
    }

    return (
      <div className="manage-budget">
        <h1>
          {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET__TITLE}&nbsp;
          {language.translate[`FINANCE__BUDGETING__MANAGE_BUDGET__${type}`]}
        </h1>
        <h2>

          {budget.periode && user_group.id > 11 && activeUnitId > 9
            ? (`RAPBY ${budget.periode}`) : (`RAPBS ${budget.periode}`) }

          <br />
          {budget.school_unit_code && budget.school_unit_code.name}
        </h2>
        { type === 'OUTCOME'
          && (
          <table className="table">
            <thead>
              <tr>
                <th className="manage-budget__row" rowSpan={2}>Biaya</th>
                <th rowSpan={1}>Total</th>
                <th rowSpan={1}>Plafon</th>
                <th rowSpan={1}>%</th>
              </tr>
            </thead>
            <tbody>
              {this.renderPlafondRows()}
            </tbody>
          </table>
)
        }
        {
          type === 'OUTCOME'
            ? (
              <Select
                name="divisi"
                data={divisi.list}
                value={divisiValue}
                onChange={this._onChangeDivisi}
              />
            )
            : (<h1>Keuangan</h1>)
        }

        <form onSubmit={this.onSubmit}>
          <ListForm>
            <BudgetForm
              code={code}
              status={status}
              coa={coa}
              tema={tema}
              type={type}
              semesterOne={semesterOne}
              semesterTwo={semesterTwo}
              onAddSemester={this.onAddSemester}
              onDeleteSemester={this.onDeleteSemester}
              semesterTwoError={error.semesterTwo}
              semesterOneError={error.semesterOne}
              onChange={this.onClickCheckbox}
              recommendations={recommendations}
              handleEditBudgetDetail={this.onSaveDetail}
              handleDeleteBudgetDetail={handleDeleteBudgetDetail}
              handleFileUpload={handleFileUpload}
              handleFileDownload={handleFileDownload}
              onUpdateForm={this.onUpdateForm}
              onUpdateError={this.onUpdateError}
              onSearchCoa={this.onSearchCoa}
              onSearchTema={this.onSearchTema}
              user={user}
              workflow={workflow}
              selectedDivisi={selectedDivisi}
              plafonds={availablePlafonds}
            />
            {this.renderButton()}

          </ListForm>
        </form>
        { type === 'OUTCOME' && workflow != '' && (

          <div className="workflow_history">
            <h2>Workflow</h2>
            {this.renderWorkflowHistory(workflow)}
          </div>
        )
     }
      </div>
    );
  }
}
ManageBudget.propTypes = {
  handleGetCoaOptions: PropTypes.func,
  handleGetBudgetDetail: PropTypes.func,
  handleAddBudgetDetail: PropTypes.func,
  handleGetFlaponDetail: PropTypes.func,
  handleEditBudgetDetail: PropTypes.func,
  handleDeleteBudgetDetail: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleGetPlafondOptions: PropTypes.func,
  handleGetTemaOptions: PropTypes.func,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
  plafond: PropTypes.object,
  tema: PropTypes.object,
};
ManageBudget.defaultProps = {
  handleGetCoaOptions: noop,
  handleGetBudgetDetail: noop,
  handleAddBudgetDetail: noop,
  handleGetFlaponDetail: noop,
  handleEditBudgetDetail: noop,
  handleDeleteBudgetDetail: noop,
  handleGetPlafondOptions: noop,
  handleFileUpload: noop,
  handleFileDownload: noop,
  handleGetTemaOptions: noop,
  user: null,
  history: null,
  tema: {},
  plafond: {},
};
