import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import BudgetUnit from './components/BudgetUnit.component';
import BudgetPlafon from './components/BudgetPlafon.component';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import { FINANCE_ADD_BUDGET_FORM_FIELDS  } from '../../../../constants/finance/budgeting/budget.constant';
import { Button, Select, SearchSelect, Input, Textarea } from '../../../../components/base';
import { errorAlert, successAlert, successAlertReload } from '../../../../utils/alert.util';
import {format} from 'date-fns';


export default class AddBudget extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);

    //this.deletePlafon = this.deletePlafon.bind(this);
    //this._onAddList = this._onAddList.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);

    this.state = {
      form: {
        value: {
          plafon: [],
        },
        error: {
          plafon: '',

        },
      },

    };
  }

  componentDidMount() {
    const { handleGetPlafondOptions, handleGetSchoolUnitOptions, handleGetPeriodeOptions, user  } = this.props;
    handleGetPlafondOptions();
    handleGetSchoolUnitOptions().then(
      () => this.setState({unit_list : this.props.unit.list.slice(1)})
    );
    handleGetPeriodeOptions();
    
    


  }



  // on formchange untuk form

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;

    // console.log(this.state.form)
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
         
      };
    });
    // console.log(name);
   
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    const {plafond_name} = form.value;
    // const {code_plafond} = form.value;

    const {code_plafond} = form.value;

    // const {code_class} = form.value;

    plafon.push({
      // code_class :'',

      // code_plafond : ',',
      plafond_name:',',

      code_plafond : ',',

    
    // plafon.push({
      tipePlafon: '',
      // divisi: '',

      nominal: 0,
      //period:'',
    });

    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
          plafond_name,
          // code_plafond,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { plafon } = prevState.form.value;
      const list = prevState.form.value.plafon;

      list.splice(idx, 1);
      return {
        ...prevState,
        plafon: list
      };
    });
  }
  // on formchange untuk tabel
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    // console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafond_name;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ?
              { plafond_name: newPlafon } :
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }

  onSubmit(e) {
    e.preventDefault();
    // const { form} = this.state;
    const { form } = this.state;
    const { handleAddBudget, history, user } = this.props;
    const { workingUnit, prm_school_units_id , school_unit_code ,plafond } = user;

    const listplafond = form.value.plafon;
    const uniqueplafond = [];
    let valuenull = 0;
    listplafond.forEach(function(obj) {
        if (uniqueplafond.indexOf(obj.plafond_id) === -1) uniqueplafond.push(obj.plafond_id);
        if(obj.nominal===0){
          valuenull = 1;
        }
    });

    //console.log(listplafond); return;
    // console.log(uniqueplafond.length)
    
    if(valuenull){
      errorAlert({
        title: 'Nominal tidak boleh 0',
      });
    }else{
      if(listplafond.length===uniqueplafond.length){
        let plafond_name = plafond;
        let unit_id = school_unit_code;
     
         // let unit_id = prm_school_units_id;
     
         if (unit_id === null && workingUnit && workingUnit.id) {
           unit_id = workingUnit.id;
         }
         handleAddBudget(form.value, history.goBack, unit_id);
      }else{
        errorAlert({
          title: 'Plafon tidak boleh ada yang sama',
        });
      }
    }
    
    // handleAddBudget(form.value, plafon, history.goBack, unit_id);
    //handleAddBudget(form.value, history.goBack, unit_id);
  }

  deletePlafon(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({people: array});
    }
  }

  render() {
    const { form } = this.state;
    const { value } = form;
    const {plafond = {}, unit = {}, periode = {}} = this.props;
    const { user } = this.props;
    const { user_group } = user;
    const year = format(new Date(), 'yyyy');
    const period = [{label: year, value: year}]
   
    if(this.state.unit_list && user_group.name === 'Keuangan'){
      var output =  this.state.unit_list.filter(unit => unit.value < 9); 
    }
    else if(this.state.unit_list && user_group.name != 'Keuangan')
    {var output2 =  this.state.unit_list.filter(unit => unit.label != "MANAGER OPSDIK" && unit.label != "YAYASAN"); }
    
    
    return (
      
      <div className="add-budget">
        <h1>Buat Anggaran</h1>
        <form onSubmit={this.onSubmit}>
          <Select
            name="period"
            label="Periode"
            onChange={this._onFormChange}
            placeholder="Periode"
            data={period}
            value={value.period}
          />
          <Select
            name="school_unit_code"
            label="Unit Sekolah"
            onChange={this._onFormChange}
            placeholder="Pilih Unit Sekolah"
            data={user_group.name === 'Keuangan'? output:output2}
            value={value.school_unit_code}
          />
          {/* {console.log(unit_list2)} */}
          <Textarea
            name="desc"
            value={value.desc}
            label="Deskripsi"
            onChange={this._onFormChange}
          />
          <BudgetPlafon
          
            plafond_name={plafond.list}
            onAddList={this._onClickAdd}
            list={value.plafon}
            onDeleteList={this._onDeleteList}
            onChange={this._onFormChange}
            
          />
          
          <div className="add-budget">
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
AddBudget.propTypes = {
  handleAddBudget: PropTypes.func,
  handleGetPlafondOptions: PropTypes.func,
  // handleGetCodeClassOptions : PropTypes.func,
  handleGetSchoolUnitOptions : PropTypes.func,
  handleGetPlafondOptions: PropTypes.func,
  // handleGetCodeClassOptions : PropTypes.func,
  handleGetSchoolUnitOptions : PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
AddBudget.defaultProps = {
  handleAddBudget: noop,
  // handleGetCodeClassOptions :noop,
  handleGetPlafondOptions: noop,
  handleGetSchoolUnitOptions: noop,
  user: null,
};
