import React, { PureComponent } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, filter, map,
} from 'lodash';
import { Button, Textarea } from '../../../../components/base';
import BudgetRapbuDetailRapby from './components/BudgetRapbuDetailRapby.component';
import language from '../../../../languages';
import { normalizeAmount } from '../../../../utils/transformer.util';
import { printDetailRapbs } from '../../../../utils/print.util';

export default class ManageBudgetRapbuDetailRapby extends PureComponent {
  constructor(props) {
    super(props);
    this.onSetForm = this.onSetForm.bind(this);
    this.onSaveRecommendations = this.onSaveRecommendations.bind(this);
    this.onSubmitApproval = this.onSubmitApproval.bind(this);
    this.onSelectBudgetDetail = this.onSelectBudgetDetail.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onChangePengeluaran = this.onChangePengeluaran.bind(this);

    this.state = {
      data: {
        budget: [],
        pengeluaran: [],
        pendapatan: [],
      },
      budgetSummary: {
        budgetloop: {},
      },
      tema: [],
      pengeluaranSummary: {
        pengeluaranloop: {},
      },
      pendapatanSummary: {
        pendapatanloop: {},
      },

      selectedDivisi: null,
    };


    // console.log(this.state)
  }

  componentDidMount() {
    this.onSetForm();
    this.onSearchTema();
  }

  onSearchTema(keyword = null) {
    // let divisiValue = this.state.selectedDivisi?this.state.selectedDivisi:[];
    const { handleGetTemaOptions } = this.props;
    handleGetTemaOptions({
      keyword,
      // divisiValue
    });

    // console.log(keyword)
    // console.log(divisiValue)
  }

  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { onChange, idx } = this.props;
    // console.log(onChange)
    // console.log(idx)
    // console.log(this.props)

    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });

    // console.log(dataset.arrayPosition)
  }

  onChangePengeluaran(event) {
    const { onChange, selectedDivisi } = this.props;
    onChange(event, 'Pengeluaran', selectedDivisi);
  }

  async onSetForm(keyword = null) {
    const {
      handleGetBudgetDetailRapbu, location, handleGetDivisiOptions, handleGetTemaOptions,
    } = this.props;
    const { account_unique_id, head_unique_id, value } = location.state;
    const res = await handleGetBudgetDetailRapbu({ account_unique_id, head_unique_id, type: 'DETAIL_RAPBU' });
    const divisi = await handleGetDivisiOptions({
      keyword,
      label: (value === 5) ? 'keuangan' : 'manajerial',
    });

    const tema = await handleGetTemaOptions({ keyword, divisiValue });
    let divisiValue = parseInt(divisi[0].value);
    // console.log(this.props)
    // console.log(tema)

    this.setState({
      budget: res.budget,
      pengeluaran: res.pengeluaran,
      pendapatan: res.pendapatan,
      list: res,
      budgetSummary: {
        budgetloop: res.budget || [],
      },
      pendapatanSummary: {
        pendapatanloop: res.pendapatan || [],
      },
      pengeluaranSummary: {
        pengeluaranloop: res.pengeluaran || [],
      },

      // selectedDivisi: parseInt(divisi[0].value),

    });
    // console.log(res.budget)
  }

  onSubmitApproval() {
    const { selectedBudgetDetails } = this.state;
    const { handleSubmitApproval, location, history } = this.props;
    const { head_unique_id } = location.state;

    handleSubmitApproval({
      head: head_unique_id,
      selectedBudgetDetails,
    }, history.goBack);
  }

  onSaveRecommendations() {
    const { handleSaveRecommendations, history } = this.props;

    handleSaveRecommendations({
    }, history.goBack);
  }


  onSelectBudgetDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { selectedBudgetDetails } = this.state;
    const index = selectedBudgetDetails.indexOf(value);
    const newArray = selectedBudgetDetails.slice(0, selectedBudgetDetails.length);

    if (index !== -1) {
      if (checked === false) {
        newArray.splice(index, 1);
      }
    } else if (checked) {
      newArray.push(value);
    }

    this.setState(prevState => ({
      ...prevState,
      selectedBudgetDetails: newArray,
    }));
  }

  renderButton() {
    const { location, user } = this.props;
    const { type } = location.state;
    const { user_groups_id, user_group } = user;
    const submitButton = (
      <div key="button_submit">
        <Button
          type="button"
          onClick={this.onSubmitApproval}
          title={(user_group.name !== 'Bendahara') ? 'Submit' : 'Approve'}
        />
      </div>
    );
    const saveButton = (
      <div key="button_save">
        <Button
          type="button"
          // onClick={this.onSaveRecommendations}
          title="Save"
        />
      </div>
    );
    const rejectButton = (
      <div key="button_reject">
        <Button
          type="button"
          // onClick={this.confirmReject}
          title="Reject"
        />

      </div>
    );

    const buttons = [];


    // console.log(user_group.name);

    if (user_group.name === 'Keuangan') {
      buttons.push(submitButton);
      return buttons;
    }

    return false;
  }

  render() {
    // console.log(type)

    const {
      pendapatanSummary, budgetSummary,
      pengeluaranSummary,
    } = this.state;
    const { pendapatan } = pendapatanSummary;
    const { pengeluaran } = pengeluaranSummary;
    const { budget } = budgetSummary;
    const {
      location, user, tema = {}, selectedDivisi, divisi = {}, onSearchTema,
    } = this.props;
    const { type, code } = location.state;

    // console.log(tema)

    return (

      <div className="manage-budget">
        <div className="table_title">
          <h1>
            {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET_DETAIL__TITLE}&nbsp;
            {language.translate[`FINANCE__BUDGETING__MANAGE_BUDGET__${type}`]}
          </h1>
          <h2>
            {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET_DETAIL__RAPBY}&nbsp;
          </h2><br />
        </div>

        <div className="print">
          <Button
            title="Print"
            onClick={() => {
              printDetailRapbs('table_title', 'table', {
                pageSize: 'A4 landscape',
                printHeader: {
                  repeated: false,
                },
              });
            }}
          />
        </div>
        <br />
        <br />
        <BudgetRapbuDetailRapby
          budget={budget}
          pendapatan={pendapatan}
          pengeluaran={pengeluaran}
          budgetSummary={budgetSummary}
          pendapatanSummary={pendapatanSummary}
          pengeluaranSummary={pengeluaranSummary}
          code={code}
          onChange={this.onChangePengeluaran}
          onSelectBudgetDetail={this.onSelectBudgetDetail}
          user={user}
          onSearchTema={this.onSearchTema}
          tema={tema}
        />
      </div>
    );
  }
}
ManageBudgetRapbuDetailRapby.propTypes = {
  handleGetBudgetDetail: PropTypes.func,
  handleGetBudgetDetailRapbu: PropTypes.func,
  handleGetTemaOptions: PropTypes.func,
  handleSaveRecommendations: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleReject: PropTypes.func,
  location: PropTypes.object.isRequired,
  onSearchTema: PropTypes.object,
  idx: PropTypes.number.isRequired,
  // handleGetDivisiOptions : PropTypes.func,
  user: PropTypes.object,
  onChange: PropTypes.func,
  tema: PropTypes.object,
  history: PropTypes.object,
  // tema: PropTypes.array,
};
ManageBudgetRapbuDetailRapby.defaultProps = {
  handleGetBudgetDetail: noop,
  handleGetBudgetDetailRapbu: noop,
  handleSaveRecommendations: noop,
  handleSubmitApproval: noop,
  onSearchTema: noop,
  handleGetTemaOptions: noop,
  // handleGetDivisiOptions : noop,
  handleReject: noop,
  onChange: noop,
  user: null,
  history: null,
  tema: {},
  // tema: [],
};
