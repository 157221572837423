import axios from 'axios';
import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';
import {
  errorAlert, successAlert, successAlertReload, successAlertBack,
} from '../../utils/alert.util';
import {
  addBudgetDetailFormat, saveBudgetTransferFormat, editBudgetDetailFormat,
  saveManageCashJournalFormat, saveManageBankJournalFormat, managePaymentJournalFormat,
  manageAdjustmentJournalFormat, manageClosingJournalFormat, manageNonKasJournalFormat,
} from '../../utils/middleware.util';
import { normalizeAmount } from '../../utils/transformer.util';
import language from '../../languages';


const { CancelToken } = axios;
let source;
/*
BUDGET START HERE
*/
export function getBudget(payload = {}) {
  return async () => {
    try {
      const { period = 2020, page = 1 } = payload;
      const requestBody = new FormData();
      requestBody.append('periode', period);
      requestBody.append('page', page);
      requestBody.append('unit_id', payload.unit_id);
      requestBody.append('paging', payload.isChecked);
      const res = await api.BUDGET(requestBody, '/head/list', { additional: ['lang'] });

      // console.log(page)
      // console.log(payload) isinya unit id ke berapa
      // console.log(res.current_page)
      // console.log(res.data)
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function addBudget(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      // console.log(payload);
      await api.BUDGET(payload, '/head/add', { additional: ['lang', 'create_by'] });
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Pendapatan Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function addFundRequest(payload, goBack = null) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const formattedPayload = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      // console.log(formattedPayload);

      formattedPayload.amount = normalizeAmount(payload.amount);
      formattedPayload.unit_id = unit_id;
      const res = await api.BUDGET_REQUEST(formattedPayload, '/add');
      if (goBack === null) {
        return res.data;
      }
      goBack();
      return true;
    } catch (err) {
      errorAlert({
        title: 'Tambah Rencana Kegiatan Bulanan Gagal',
        errorMessage: 'Mohon di cek kembali dan Periksa lengkapi form',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function editBudget(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      // console.log(payload);
      await api.BUDGET(payload, '/head/edit', { additional: ['lang', 'create_by'] });
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Edit Anggaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteBudget(payload = {}) {
  return async (dispatch) => {
    try {
      const { head_unique_id } = payload;
      const requestBody = {
        head_unique_id,
      };
      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET(requestBody, '/head/delete');

      successAlertReload({ title: 'Hapus Anggaran Berhasil' });
    } catch (err) {
      errorAlert({
        title: 'Hapus Anggaran Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getFlaponDetail(payload) {
  return async (dispatch) => {
    try {
      let path = '/detail/list';
      let requestBody = {};
      const { account_unique_id, head_unique_id, type = '' } = payload;
      dispatch(commonActions.setLoading({ show: true }));
      if (type === 'RAPBU') {
        requestBody = {
          filters: {
            head: head_unique_id,
          },
        };
        path = '/detail/rapbu';
      } else if (type === 'OUTCOME') {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
            listType: 'OUTCOME',
          },
        };
      } else if (type === 'INCOME') {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
            listType: 'INCOME',
          },
        };
      } else {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
          },
        };
      }
      const res = await api.BUDGET(requestBody, path);
      // console.log(res)
      if (type === 'RAPBU') {
        return res.data;
      }

      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function handleGetBudgetRapbuRekapExcelDetail(payload = {}) {
  return async () => {
    try {
      const { head_unique_id: unique_id } = payload;
    
      const res = await api.REPORT_RAPBS({ unique_id }, '/rapbs/exportrekap');

      return {
        result: res.data,
      };
    } catch (err) {
      throw err;
    }
  };
}


export function handleGetBudgetRapbuRekapDetail(payload = {}) {
  return async () => {
    try {
      const {
        page = 1, currentPage, head_unique_id,
      } = payload;
      const requestBody = new FormData();
      requestBody.append('unique_id', head_unique_id);
      requestBody.append('page', page);
      requestBody.append('currentPage', currentPage);


      const res = await api.REPORT_RAPBS(requestBody, '/rapbs/rekap', { additional: ['lang'] });
      // console.log(res)

      return {
        result: res.data,
        currentPage: res.data.data.current_page,
        totalPage: res.data.data.last_page,
        total: res.data.data.total,
      };
    } catch (err) {
      throw err;
    }
  };
}


export function handleGetBudgetDetailRapbu(payload) {
  return async (dispatch) => {
    try {
      let path = '/report/rapbs';
      let requestBody = new FormData();
      const {
        head_unique_id, type = '', page = 2, current_page, last_page, total,
      } = payload;
      dispatch(commonActions.setLoading({ show: true }));
      if (type === 'DETAIL_RAPBU') {
        requestBody.append('unique_id', head_unique_id);
        requestBody.append('page', page);
        requestBody.append('current_page', current_page);
        requestBody.append('last_page', last_page);
        requestBody.append('total', total);

        path = '/rapbs/detail';
      } else {
        requestBody = {
          filters: {
            unique_id: head_unique_id,
            page,
            current_page,
            last_page,
            total,
          },
        };
      }
      const res = await api.REPORT_RAPBS(requestBody, path);
      
      if (type === 'DETAIL_RAPBU') {
        return res.data;
      }
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBudgetDetail(payload) {
  return async (dispatch) => {
    try {
      let path = '/detail/list';
      let requestBody = {};
      const { account_unique_id, head_unique_id, type = '' } = payload;
      dispatch(commonActions.setLoading({ show: true }));
      if (type === 'RAPBU') {
        requestBody = {
          filters: {
            head: head_unique_id,
          },
        };
        path = '/detail/rapbu';
        // console.log(path)
      } else if (type === 'OUTCOME') {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
            listType: 'OUTCOME',
          },
        };
      } else if (type === 'INCOME') {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
            listType: 'INCOME',
          },
        };
      } else {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
          },
        };
      }
      const res = await api.BUDGET(requestBody, path);
      if (type === 'RAPBU') {
        return res.data;
      }
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBudgetDetailRAPBY(payload) {
  return async (dispatch) => {
    try {
      let path = '/detail/list';
      let requestBody = {};
      const { account_unique_id, head_unique_id, type = '' } = payload;
      dispatch(commonActions.setLoading({ show: true }));
      if (type === 'RAPBY') {
        requestBody = {
          filters: {
            head: head_unique_id,
          },
        };
        path = '/detail/rapbu';
      } else if (type === 'OUTCOME') {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
            listType: 'OUTCOME',
          },
        };
      } else if (type === 'INCOME') {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
            listType: 'INCOME',
          },
        };
      } else {
        requestBody = {
          filters: {
            account: account_unique_id,
            head: head_unique_id,
          },
        };
      }
      const res = await api.BUDGET(requestBody, path);
      if (type === 'RAPBY') {
        return res.data;
      }
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function addBudgetDetail(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { head_unique_id, account_unique_id, code } = payload;
      const data = addBudgetDetailFormat(payload);
      const requestBody = {
        head: head_unique_id,
        account: account_unique_id,
        account_type: code,
        data,
      };
      const res = await api.BUDGET(requestBody, '/detail/add');
      successAlert({ title: 'Menambah Anggaran Berhasil' });
      return res.data.data;
    } catch (err) {
      errorAlert({
        title: 'Menambah Anggaran Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editBudgetDetail(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { head, account, code } = payload;
      const data = editBudgetDetailFormat(payload);
      const requestBody = {
        head,
        account,
        account_type: code,
        data,
      };
      await api.BUDGET(requestBody, '/detail/edit');
      successAlert({ title: 'Update Anggaran Berhasil' });
      return true;
    } catch (err) {
      errorAlert({
        title: 'Update Anggaran Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteBudgetDetail(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('details_unique_id', id);
      await api.BUDGET(requestBody, '/detail/delete');
      successAlert({ title: 'Hapus Detail Anggaran Berhasil' });
      return id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Detail Anggaran Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

/*
BUDGET END HERE
*/

/*
NON BUDGET START HERE
*/
export function getNonBudget(payload = {}, unit_id) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const requestBody = new FormData();
      requestBody.append('page', page);
      requestBody.append('unit_id', unit_id);
      const res = await api.NON_BUDGET_POST(requestBody, '/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function addNonBudget(payload, goBack, unit_id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('file_number', payload.file_number);
      requestBody.append('date', payload.date);
      requestBody.append('code_of_account', payload.code_of_account.code);
      requestBody.append('activity', payload.activity);
      requestBody.append('description', payload.description);
      requestBody.append('amount', normalizeAmount(payload.amount));
      requestBody.append('unit_id', unit_id);
      await api.NON_BUDGET_POST(requestBody, '/add');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Non Anggaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editNonBudget(payload, goBack, unit_id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', payload.id);
      requestBody.append('file_number', payload.file_number);
      requestBody.append('date', payload.date);
      requestBody.append('code_of_account', payload.code_of_account.code);
      requestBody.append('activity', payload.activity);
      requestBody.append('description', payload.description);
      requestBody.append('amount', normalizeAmount(payload.amount));
      requestBody.append('unit_id', unit_id);
      await api.NON_BUDGET_POST(requestBody, '/edit');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Edit Non Anggaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteNonBudget(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', id);
      await api.NON_BUDGET_POST(requestBody, '/delete');
    } catch (err) {
      errorAlert({
        title: 'Hapus Non Anggaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
NON BUDGET END HERE
*/

/*
BUDGET TRANSFER START HERE
*/
export function getBudgetTransfer(payload = {}, unit_id) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const requestBody = new FormData();
      requestBody.append('page', page);
      requestBody.append('unit_id', unit_id);
      const res = await api.BUDGET(requestBody, '/relocation/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getBudgetTransferById(id) {
  return async () => {
    try {
      const res = await api.BUDGET({ id }, '/relocation/get');
      return res.data;
    } catch (err) {
      throw err;
    }
  };
}

export function getBudgetCheckList(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const {
        code_of_account, type, head, periode,
      } = payload;
      const res = await api.BUDGET({
        filters: {
          code_of_account,
          type,
          head,
          periode,
        },
      }, '/detail/list');
      return [...res.ganjil, ...res.genap];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveBudgetTransfer(payload, goBack, unit_id) {
  return async (dispatch) => {
    try {
      const formattedPayload = saveBudgetTransferFormat(payload);
      formattedPayload.unit_id = unit_id;

      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET(formattedPayload, '/relocation/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Simpan Transfer Anggaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteBudgetTransfer(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', id);
      await api.BUDGET(requestBody, '/relocation/delete');
      return id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Transfer Anggaran Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
BUDGET TRANSFER END HERE
*/


/*
FUND REQUEST START HERE
*/
export function getFundRequest(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));

      const { page = 1 } = payload;
      const requestBody = new FormData();
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      requestBody.append('page', page);
      requestBody.append('unit_id', unit_id);
      const res = await api.BUDGET_REQUEST(requestBody, '/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getFundRequestAvailableCoa(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET_REQUEST(payload, '/code-of-account');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'coa',
        data: res.data,
      }));
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function getFundRequestById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.BUDGET_REQUEST({ id }, '/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getDetailFund(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { code_of_account, type } = payload;
      const res = await api.BUDGET({
        filters: {
          code_of_account,
          type,
        },
      }, '/detail/list');
      return [...res.data.ganjil, ...res.data.genap];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAvailableBudgetDetails(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const {
        code, type, head, periode,
      } = payload;
      const res = await api.BUDGET_REQUEST({
        filters: {
          code,
          type,
          head,
          periode,
        },
      }, '/budget-details');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function rejectFundRequest(payload, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET_REQUEST(payload, '/reject');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tolak Permintaan Dana Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function submitFundRequest(payload, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET_REQUEST({ id: payload.id }, '/submit');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Submit RKB Gagal',
        errorMessage: language.translate[err.message],
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteFundRequest(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET_REQUEST({ id: payload.id }, '/delete');
      return res;
    } catch (err) {
      errorAlert({
        title: 'Hapus Permintaan Dana Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
FUND REQUEST END HERE
*/


/*
REALIZATION START HERE
*/
export function getRealization(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const requestBody = new FormData();
      requestBody.append('page', page);
      const res = await api.REALIZATION_POST(requestBody, '/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getDetailRealization(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.REALIZATION_POST({ ...payload }, '/get');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function addRealization(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const data = await api.REALIZATION_POST(payload, '/add');
      return data;
    } catch (err) {
      errorAlert({
        title: 'Tambah Realisasi Gagal',
      });
      return false;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function editRealization(payload, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', payload.id);
      requestBody.append('file', payload.file[0]);
      requestBody.append('budget_detail_unique_id', payload.selectedBudget.unique_id);
      requestBody.append('description', payload.description);
      requestBody.append('amount', normalizeAmount(payload.amount));
      await api.REALIZATION_POST(requestBody, '/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Edit Realisasi Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteRealization(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('id', id);
      await api.REALIZATION_POST(requestBody, '/delete');
      return id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Realisasi Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
REALIZATION END HERE
*/


/*
UPLOAD RAPBU START HERE
*/
export function uploadRapbuFile(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { file, periode, head } = payload;
      const requestBody = new FormData();
      requestBody.append('periode', periode);
      requestBody.append('head', head);
      requestBody.append('file', file);
      const res = await api.BUDGET(requestBody, '/detail/upload', {}, {
        'content-type': 'multipart/form-data',
      });

      if (res.message === 'Failed') {
        errorAlert({
          title: res.data,
        });
      } else {
        successAlertBack({
          title: `${res.message} & rapby`,
          goBack,
        });
      }

      // goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah File Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteRapbuFile(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('unique_id', id);
      await api.BUDGET(requestBody, '/head/delete');
      return id;
    } catch (err) {
      errorAlert({
        title: 'Hapus Pendapatan Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
UPLOAD RAPBU END HERE
*/

/*
PAYMENT JOURNAL START HERE
*/
export function getPaymentJournal(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ page }, '/pembayaran/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getPaymentJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/pembayaran/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function managePaymentJournal(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(managePaymentJournalFormat(payload), '/pembayaran/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Pembayaran Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
PAYMENT JOURNAL END HERE
*/

/*
ADJUSTMENT JOURNAL START HERE
*/
export function getAdjustmentJournal(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ page }, '/penyesuaian/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getAdjustmentJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/penyesuaian/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageAdjustmentJournal(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(manageAdjustmentJournalFormat(payload), '/penyesuaian/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Penyesuaian Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
ADJUSTMENT JOURNAL END HERE
*/

/*
CLOSING JOURNAL START HERE
*/
export function getClosingJournal(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ page }, '/penutup/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getClosingJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/penutup/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageClosingJournal(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(manageClosingJournalFormat(payload), '/penutup/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Penutup Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
CLOSING JOURNAL END HERE
*/

/*
NON KAS JOURNAL START HERE
*/
export function getNonKasJournal(payload = {}) {
  return async () => {
    try {
      const { page = 1 } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ page }, '/non-kas/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getNonKasJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/non-kas/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageNonKasJournal(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(manageNonKasJournalFormat(payload), '/non-kas/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Non Kas Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
NON KAS JOURNAL END HERE
*/

/*
GENERAL JOURNAL START HERE
*/
export function getGeneralJournal(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const { page = 1 } = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      const res = await api.FINANCE_JOURNAL_POST({ ...payload, page, unit_id }, '/list');
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getGeneralJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { journal_number } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ journal_number }, '/get');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageGeneralJournal(payload = {}, goBack) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      await api.FINANCE_JOURNAL_POST({ ...payload, unit_id }, '/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Umum Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getGeneralJournalPreview(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { journal_number } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ journal_number }, '/preview');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
GENERAL JOURNAL END HERE
*/
export function deleteJournal(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(payload, '/delete');
    } catch (err) {
      errorAlert({
        title: 'Hapus Jurnal Gagal',
        errorMessage: language.translate[err.message],
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteJournalItem(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.FINANCE_JOURNAL_POST(payload, '/detail/delete');
    } catch (err) {
      errorAlert({
        title: 'Hapus Detail Jurnal Gagal',
        errorMessage: language.translate[err.message],
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCashJournal(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const { page = 1 } = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const res = await api.FINANCE_JOURNAL_POST({ ...payload, page, unit_id }, '/kas/list');
      dispatch(commonActions.loadJournalList(res));
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function postJournalPosting(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id, unit_id }, '/post');
      dispatch(commonActions.postJournalPosting({
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function postJournalUnposting(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/unpost');
      dispatch(commonActions.postJournalUnposting({
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCashJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/kas/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCashJournalPreview(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/kas/preview');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageCashJournal(payload = {}, goBack) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      await api.FINANCE_JOURNAL_POST(saveManageCashJournalFormat({ submitted_by: user.name, ...payload, unit_id }), '/kas/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Kas Gagal',
        errorMessage: language.translate[err.message] || err.message,
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAvailableFundRequests(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const formattedPayload = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      formattedPayload.unit_id = unit_id;
      const res = await api.FINANCE_JOURNAL_POST(formattedPayload, '/fund-requests');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBankJournal(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const { page = 1 } = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      const res = await api.FINANCE_JOURNAL_POST({ ...payload, page, unit_id }, '/bank/list');
      return {
        result: res.data,
        currentPage: res.current_page,
        totalPage: res.last_page,
        total: res.total,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getBankJournalById(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/bank/get');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBankJournalPreview(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.FINANCE_JOURNAL_POST({ id }, '/bank/preview');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function manageBankJournal(payload = {}, goBack) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      await api.FINANCE_JOURNAL_POST(saveManageBankJournalFormat({ submitted_by: user.name, ...payload, unit_id }), '/bank/save');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Jurnal Bank Gagal',
        errorMessage: language.translate[err.message] || err.message,
      });
      console.log(err);
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getOutcome(payload = {}) {
  return async () => {
    try {
      const { limit = 10, offset = 0 } = payload;
      const res = await api.FINANCE_OUTCOME('', {
        params: {
          limit,
          offset,
          sortBy: 'updatedAt',
          orderBy: 'desc',
        },
      });
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function getRapbu(payload = {}) {
  return async () => {
    try {
      const { limit = 10, offset = 0 } = payload;
      const res = await api.FINANCE_RAPBU('', {
        params: {
          limit,
          offset,
          sortBy: 'updatedAt',
          orderBy: 'desc',
        },
      });
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function calculateTax(payload = {}) {
  return async () => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    const res = api.TAX_POST(payload, '/calculate', options)
      .then(result => result.result)
      .catch();
    return res;
  };
}

export function getTaxRecipientParam(payload = {}) {
  return async () => {
    try {
      if (source) {
        source();
      }
      const options = {
        cancelToken: new CancelToken(((c) => {
          source = c;
        })),
      };
      const res = api.TAX_POST(payload, '/param/recipient', options)
        .then(result => result.result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    }
  };
}

export function getTaxPtkpParam(payload = {}) {
  return async () => {
    try {
      const res = await api.TAX_POST(payload, '/param/ptkp');
      return res.result;
    } catch (err) {
      throw err;
    }
  };
}

export function submitApproval(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET(payload, '/detail/rapbu/submit');
      // goBack();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveRecommendations(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      await api.BUDGET(payload, '/detail/rapbu/save');
      goBack();
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function rejectRapbu(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET(payload, '/detail/rapbu/reject');
      // goBack();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function addMonthlyActivityPlan(payload, goBack) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const formattedPayload = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      formattedPayload.amount = normalizeAmount(payload.amount);
      formattedPayload.unit_id = unit_id;

      await api.BUDGET_REQUEST(formattedPayload, '/add');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah Rencana Kegiatan Bulanan Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMonthlyActivityPlantCoa(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));

      const res = await api.BUDGET_REQUEST(payload, '/code-of-account')
        .then(result => result)
        .catch();

      dispatch(commonActions.getOptionsSucceeded({
        entity: 'coa',
        data: res.data,
      }));
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMonthlyActivityPlantTema(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.BUDGET_REQUEST(payload, '/tema')
        .then(result => result)
        .catch();

      dispatch(commonActions.getOptionsSucceeded({
        entity: 'tema',
        data: res.data,
      }));
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function getAvailableBudgetDetailsRKB(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const formattedPayload = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      formattedPayload.unit_id = unit_id;
      const res = await api.BUDGET_REQUEST(formattedPayload, '/budget-details');
      // console.log(res.data)

      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAvailableBudgetDetailsRKBTema(payload) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const formattedPayload = payload;
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      formattedPayload.unit_id = unit_id;
      const res = await api.BUDGET_REQUEST(formattedPayload, '/budget-details2');
      // console.log(res.data)

      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

// Saldo-Awal//
export function getBeginningBalance(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      payload.unit_id = unit_id;
      const res = await api.FINANCE_STARTING_BALANCE(payload, '/get');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function saveBeginningBalance(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_STARTING_BALANCE({ data: payload, unit_id }, '/save');
      successAlert({ title: 'Berhasil menyimpan saldo awal.' });
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function postBeginningBalance(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_STARTING_BALANCE({ data: payload, unit_id }, '/post');
      successAlert({ title: 'Saldo awal berhasil di posting.' });
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTemaCoaMap() {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_SETTINGS({ unit_id }, '/tema-coa-map/list');
      return res.data || [];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function updateTemaCoaMap(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.FINANCE_SETTINGS({ ...payload, unit_id }, '/tema-coa-map/update');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function transferFundRequest(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id = '' } = payload;
      const res = await api.BUDGET_REQUEST({ id }, '/transfer');
      return res;
    } catch (err) {
      errorAlert({
        title: 'Transfer Dana Gagal',
        errorMessage: 'Mohon di cek kembali dan Periksa lengkapi form',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function uploadFileRKB({ id, file, filename }) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const formData = new FormData();
      formData.append('file', file, filename);
      await api.BUDGET_REQUEST(formData, '/upload');
    } catch (err) {
      errorAlert({
        title: 'Upload file gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

// //////////////////
